import REGIONS from "./Regions";

export const PNG_HEADER = "13780787113102610";
export const JPG_HEADER = "255";

export const BLACKLIST_WRITEMARK_WARNING_MESSAGE =
  "Writemark access has not yet been whitelisted (https://prod-aus.elastik-emt.com) by your school, please contact your IT department and provide this message";

export const FEATURE_FLAGS = {
  HANDWRITING_V2: "handwritingV2",
  MFA: {
    ENABLED: "mfa-enabled",
    SHOW_COGNITO_LINKS: "mfa-show-cognito-links",
  },
};

export const QuestionType = {
  IMAGE: "Image",
  TEXT: "Text",
  ENTRY: "Entry",
  RTE: "Rich Text",
};

export const QuestionSelection = {
  Single: "Single",
  Multiple: "Multiple",
};

export const TestCategoryNames = {
  CATEGORY_1: "Category 1",
  CATEGORY_2: "Category 2",
  CATEGORY_3: "Category 3",
};

export const LicenceModuleTitle = {
  WRITING_ASSESSMENT: "Writing Assessment",
  PREMIUM_ASSESSMENT: "Premium Assessment",
};

export const LAMBDAS_API_METHODS = {
  AdminQueries: {
    SET_USER_PASSWORD: "/setUserPassword",
  },
};

export const LAMBDAS_APIS = {
  AdminQueries: "AdminQueries",
};

export const LicenceModuleType = {
  WRITING_ASSESSMENT: "WritingAssessment",
  PREMIUM_ASSESSMENT: "PremiumAssessment",
};

export const MenuUrls = {
  WAM_NEWREQUEST: "/wam/newrequest",
  PAM_NEWREQUEST: "/pam/newrequest",
  WAM_SCHOOLS: "/wam/wamschools",
  PAM_SCHOOLS: "/pam/licenced-schools",
  WAM_STUDENT_LICENCE_MANAGE: "/wam/student-licence-management",
  PAM_STUDENT_LICENCE_MANAGE: "/pam/student-licence-management",
};

export const APP_MODULES = {
  IST: "IST",
  MARKSBOOK: "MARKSBOOK",
};
export const ClozeAnswerSeparator = "__$$__";

export const TestTypes = {
  PREMIUM: "PREMIUM",
  LIBRARY: "LIBRARY",
};

export const defaultPermissions = {
  withAddNewTest: false,
  withDeleteTest: true,
  withAssignStudents: true,
  withEditStudents: true,
  withRemoveTest: true,
  withTestDetail: true,
  withEditTest: true,
};

export const filterTypesOptions = {
  CLASSROOM: "Classroom",
  COHORT: "Cohort",
  FOCUS_GROUP: "FocusGroup",
  NETWORK: "Network",
  STUDENT: "Student",
  AQA: "AQA",
  PEARSON: "Pearson",
  CAMBRIDGE: "Cambridge",
  PGONLINE: "PGOnline",
  NCFE: "NCFE"
};

export const testAssigmentsStatus = {
  PENDING: "PENDING",
};
export const testUnitNames = {
  LEVEL: "Level",
  RAW_SCORE: "Raw Score",
  PERCENTAGE: "Percentage",
  MINIMUM_STANDARD: "Minimum Standard",
  NATIONAL_BAND: "National Band",
  SCALED_SCORE: "Scaled Score",
  NAPLAN_SCORE: "Naplan Score",
};

export const testTypeNames = {
  SCORED_BASED: "SCORED BASED",
  PREMADE: "PREMADE",
  PREMIUM: "PREMIUM",
  NAPLAN: "NAPLAN",
  LIBRARY: "LIBRARY",
  PAT: "PAT",
  MTS: "MTS",
  MATHLETICS: "MATHLETICS",
  // TODO: keep adding as we need
};

export const tourPages = {
  GAP_ANALYSIS: "GAP_ANALYSIS",
  REVIEW_PREMIUM: "REVIEW_PREMIUM",
  // TODO: keep adding as we need
};

export const CountryCallingCode = {
  AU: "+61",
  UK: "+44",
};

export const NotificationType = {
  SET_USER_PASSWORDS: "SET_USER_PASSWORDS",
};

export const parameterNames = {
  TIME_TO_AUTO_SAVE_ESSAY_PARAMETER_NAME: "TIME_TO_AUTO_SAVE_ESSAY",
};

/**
 * Type Definitions for Phone Number Formats
 * @typedef {Object} DxEditorOptionMaskRules
 * @property {RegExp} X - The mask Rules
 *
 * @typedef {Object} DxEditorOptions
 * @property {DxEditorOptionMaskRules} maskRules
 * @property {String} mask - The Input Mask
 * @property {String} maskInvalidMessage - The message to display when the input doesn't match the mask
 *
 * @typedef {Object} PhoneNumberFormat
 * @property {DxEditorOptions} dxEditorOptions
 * @property {RegExp} pattern - pattern against which to validate a phone number
 * @property {String} patternInvalidMessage - Message to display when the input doesn't match the pattern
 * @property {String} countryCallingCode - The calling code prefix e.g. +61 for Australia
 */
export const PhoneNumberFormats = {
  /** @type {PhoneNumberFormat} */
  [REGIONS.Australia]: {
    dxEditorOptions: {
      mask: `${CountryCallingCode.AU} X00 000 000`,
      maskRules: { X: /[01-9]/ },
      maskInvalidMessage: "The phone must have a correct AU phone format",
    },
    pattern: /^[01-9]\d{8}$/,
    patternInvalidMessage: "The phone must have a correct AU phone format",
    countryCallingCode: CountryCallingCode.AU,
  },
  /** @type {PhoneNumberFormat} */
  [REGIONS.London]: {
    dxEditorOptions: {
      mask: `${CountryCallingCode.UK} X000 000000`,
      maskRules: { X: /[01-9]/ },
      maskInvalidMessage: "The phone must have a correct UK phone format",
    },
    pattern: /^[01-9]\d{9}$/,
    patternInvalidMessage: "The phone must have a correct UK phone format",
    countryCallingCode: CountryCallingCode.UK,
  },
};

export const PREMIUM_TEST_CLASS = "premium-test";

export const QR_VIEW_PROPERTIES = {
  // number of rows with group of 2 QRs that fit an A4 page when printing.
  numberOfRowsPerPage: 4,
  reactToPrintStyles: "width: '80%', margin: 'auto'",
};

export const newLoginApproachParamName = "IS_NEW_LOGIN_APPROACH_ACTIVATED";

export const CLASS_TYPE_DISPLAY_NAMES = {
  Classroom: "Classroom",
  FocusGroup: "Focus Group",
};

// used for displaying prompts and info to teachers regarding Unique student identifiers in various jurisdictions
export const UNIQUE_STUDENT_ID_TEXTS = {
  DEFAULT: {
    IDENTIFIER_NAME: "Unique student identifier",
    MORE_INFORMATION:
      "Enter the unique identifier which schools in your jurisdiction uses to uniquely identify students. This could be a student number, a student ID, or a student code.",
  },
  COUNTRIES: {
    AU: {
      DEFAULT: {
        IDENTIFIER_NAME: "Unique student identifier",
        MORE_INFORMATION:
          "Enter the unique identifier which your state or territory uses to uniquely identify students.",
      },
      VIC: {
        IDENTIFIER_NAME: "Victorian Student Number (VSN)",
        MORE_INFORMATION: `To request a student's VSN, please follow the instructions at <a href="https://www.vcaa.vic.edu.au/administration/schooladministration/student-numbers/Pages/VSNDataRequests.aspx" target="_blank">the Victorian Curriculum and Assessment Authority (VCAA)</a> `,
      },
      // NSW: {},
      QLD: {
        IDENTIFIER_NAME: "Queensland Learner Unique Identifier (LUI)",
        MORE_INFORMATION:
          'Please see <a href="https://www.qcaa.qld.edu.au/logins/qcaa-portal/landing-page" target="_blank">The QCAA Portal</a> for more information',
      },
      // SA: {},
      WA: {
        IDENTIFIER_NAME: "Western Australian Student Number (WASN)",
        MORE_INFORMATION:
          'To request a WASN, contact <a href=mailto:"numbers@scsa.wa.edu.au">numbers@scsa.wa.edu.au</a> and give details of the student’s legal surname, legal first name, ' +
          "date of birth and academic year.  It is also useful to include your school code in any correspondence.",
      },
      // TAS: {},
      // NT: {},
      ACT: {
        IDENTIFIER_NAME: "ACT Student ID (ACTSI)",
        MORE_INFORMATION:
          'See <a href="https://studentmovementregister.ed.act.edu.au" target="_blank">ACT Student Movement Register</a> for more information.',
      },
    },
    UK: {
      DEFAULT: {
        IDENTIFIER_NAME: "Unique student identifier",
        MORE_INFORMATION:
          "Enter the unique identifier which your jurisdiction uses to uniquely identify students. This could be a student number, a student ID, or a student code.",
      },
    },
  },
};

/**
 * @typedef {Object} StudentIDTexts
 * @property {string} IDENTIFIER_NAME - the name of the Unique student identifier used in prompts and user interfaces
 * @property {string} MORE_INFORMATION - the text to display in prompts and user interfaces to provide more information about the Unique student identifier
 * @example
 * {
 *  IDENTIFIER_NAME: "Victorian Student Number (VSN)",
 *  MORE_INFORMATION: "To request a student's VSN, please follow the instructions at <a href="https://www.vcaa.vic.edu.au/administration/schooladministration/student-numbers/Pages/VSNDataRequests.aspx">the Victorian Curriculum and Assessment Authority (VCAA)</a>
 * }
 */

/**
 * Returns the student ID texts for the given country and state, used in prompts and user interfaces
 * @param {string} twoLetterCountryCode e.g. "AU"
 * @param {string} stateCode e.g. "VIC"
 * @returns {StudentIDTexts} the student ID texts for the given country and state
 */
export const getStudentIDTextsForCountryAndState = (
  twoLetterCountryCode,
  stateCode
) => {
  let studentIDTexts = UNIQUE_STUDENT_ID_TEXTS.DEFAULT;
  if (
    Object.prototype.hasOwnProperty.call(
      UNIQUE_STUDENT_ID_TEXTS.COUNTRIES,
      twoLetterCountryCode
    )
  ) {
    const countryTexts =
      UNIQUE_STUDENT_ID_TEXTS.COUNTRIES[twoLetterCountryCode];
    if (Object.prototype.hasOwnProperty.call(countryTexts, stateCode)) {
      studentIDTexts = countryTexts[stateCode];
    } else if (Object.prototype.hasOwnProperty.call(countryTexts, "DEFAULT")) {
      studentIDTexts = countryTexts.DEFAULT;
    }
  }
  return studentIDTexts;
};

export const FEATURE_FLAG_DATA_TYPES = {
  BOOLEAN: "BOOLEAN",
  STRING: "STRING",
  NUMBER: "NUMBER",
  JSON: "JSON",
};

export const GENDERS = ["Male", "Female", "NonBinary", "NotProvided"];

export const ROUTE_CONDITION_PARAMETER_MAP_KEYS = {
  GROWTHBOOK_INSTANCE: "growthbookInstance",
};

/**
 * User's profile image name used to store in s3
 */
export const userProfileImageName = "profilePicture.png";

/**
 * constants used internally by the amplify Auth Hub
 */
export const AMPLIFY_AUTH_HUB_CONSTANTS = {
  AUTH_CHANNEL: "auth",
  UI_AUTH_CHANNEL: "UI Auth",
  TOAST_AUTH_ERROR_EVENT: "ToastAuthError",
  AUTH_STATE_CHANGE_EVENT: "AuthStateChange",
};

export const validateObjectVariable = (variable) =>{
  return variable && !Array.isArray(variable) && typeof variable === "object"
}
