const ROLES = {
  Admin: "Admin",
  Coordinator: "Coordinator",
  DeputyPrincipal: "DeputyPrincipal",
  Educator: "Educator",
  Parent: "Parent",
  Principal: "Principal",
  SystemAdmin: "SystemAdmin",
  Student: "Student",
  AQA: "AQA",
  PEARSON: "Pearson",
  CAMBRIDGE: "Cambridge",
  NCFE: "NCFE",
  PGOnline: "PGOnline"
};

export const PERMISSIONS = {
  ADMINISTRATION: [ROLES.SystemAdmin, ROLES.Admin],
  COORDINATION: [ROLES.Coordinator, ROLES.DeputyPrincipal, ROLES.Principal],
  EDUCATION: [ROLES.Educator],
};

/** arrays specifying a hierarchy of roles, whereby the value contains all the role names that should have less than or equal the permissions of the key */
export const PERMISSIONS_COMBINED = {
  ADMINISTRATION: [
    ...PERMISSIONS.ADMINISTRATION,
    ...PERMISSIONS.COORDINATION,
    ...PERMISSIONS.EDUCATION,
  ],
  COORDINATION: [...PERMISSIONS.COORDINATION, ...PERMISSIONS.EDUCATION],
  EDUCATION: [...PERMISSIONS.EDUCATION],
};

/**
 * A map of role names to the array of role names that should have less than or equal the permissions of the key
 * @example
 * {
 *  Admin: ["SystemAdmin", "Admin", "Coordinator", "DeputyPrincipal", "Principal", "Educator"],
 *  Coordinator: ["Coordinator", "DeputyPrincipal", "Principal", "Educator"],
 *  DeputyPrincipal: ["Coordinator", "DeputyPrincipal", "Principal", "Educator"],
 *  // ...
 *  }
 */
export const PERMISSIONS_INHERITED = Object.keys(ROLES)
  .map((role) => ({
    // find the role in one of the values of the PERMISSIONS object, determine its key, and lookup the value of that key in the PERMISSIONS_COMBINED object
    [role]:
      PERMISSIONS_COMBINED[
        Object.keys(PERMISSIONS).find((key) =>
          PERMISSIONS[key].includes(role)
        ) || role
      ],
  }))
  .reduce((acc, cur) => ({ ...acc, ...cur }), {});

export default ROLES;
